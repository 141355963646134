import React from 'react';

const Footer = () => (
  <div className="Footer">
    <div className="Footer-Social-Info-Section">
      <div className='Footer-Social-Text'>
        <h1>SOCIALS</h1>
        <h3>Check out my socials below!</h3>
      </div>
      <ul id="footer-social-links">
        <li>
          <a
            href="https://www.youtube.com/c/gabesweats/featured"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <i className="fab fa-youtube fa-5x"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/gabesweats"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <i className="fab fa-twitter fa-5x"></i>
          </a>
        </li>
				<li>
          <a
            href="https://www.tiktok.com/@gabesweats"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <i className="fab fa-tiktok fa-5x"></i>
          </a>
        </li>
        <li>
          <a
            href="https://discord.com/invite/UbSBe9A"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            <i className="fab fa-discord fa-5x"></i>
          </a>
        </li>
      </ul>
    </div>
    <div className="Footer_bottom">
      <div className="Footer_top-links">
        <a
          href="https://merchforall.com/pages/sizes/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Sizes and Fit
        </a>
        |
        <a
          href="https://merchforall.com/pages/shipping-and-returns/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Shipping & Returns
        </a>
        |
        <a
          href="https://merchforall.com/contact/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Contact Us
        </a>
      </div>
      <span>© {new Date().getFullYear()} Merch for All LLC - All Rights Reserved</span>|
      <a
        href="https://merchforall.com/legal/privacy/"
        target="_blank"
        rel="noreferrer"
        className="Footer_bottom-link"
      >
        Privacy Policy
      </a>
      |
      <a
        href="https://merchforall.com/legal/terms/"
        target="_blank"
        rel="noreferrer"
        className="Footer_bottom-link"
      >
        Terms of Service
      </a>
    </div>
  </div>
);


export default Footer;